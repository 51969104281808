import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Problem #2`}</h5>
    <h6>{`Combining a modern javascript framework with `}<em parentName="h6">{`AR.js`}</em></h6>
    <p><strong parentName="p">{`AR.js`}</strong>{` is not designed to be used in a modern javascript frameworks like `}<strong parentName="p">{`react`}</strong>{`.
Therefore it was tricky to include it in a way that everything was working correctly (`}<strong parentName="p">{`marker loading`}</strong>{` and access to the `}<strong parentName="p">{`camera`}</strong>{`).`}</p>
    <p>{`As I'm coming from a background of extensively using modern frontend frameworks like `}<strong parentName="p">{`vuejs`}</strong>{` or
`}<strong parentName="p">{`react`}</strong>{` I didn't want to miss out on all the convenience it gives me.`}</p>
    <p>{`Normally in a react app you would install a library via the `}<strong parentName="p">{`node package manager (npm)`}</strong>{` but sadly
`}<strong parentName="p">{`AR.js`}</strong>{` just doesn't work this way. It expects to be used in a `}<em parentName="p">{`static`}</em>{` web page, where it is
loaded in the `}<strong parentName="p">{`head`}</strong>{` of the `}<strong parentName="p">{`html document`}</strong>{`. After being loaded it is immediately executed,
regardless of the rest of the react app beging loaded or not. So when it is loaded to fast and the
react app is not finished building up the rest of the page it didn't work.`}</p>
    <p>{`Luckily there is some tooling around `}<strong parentName="p">{`react`}</strong>{` called `}<strong parentName="p">{`gatsbyjs`}</strong>{` that makes it easy to render the
webpages on the server side and only serve `}<em parentName="p">{`static`}</em>{` web pages.
This allowed me to use `}<strong parentName="p">{`react`}</strong>{` and `}<strong parentName="p">{`AR.js`}</strong>{` together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      